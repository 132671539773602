import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'customerItemRefFilter'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    if (!args) {
      return value;
    }
    return value.filter((val) => {
      if (val.article != null) {
        let rVal = val.article.toLocaleLowerCase().includes(args);
        return rVal;
      }
    })
  }
}