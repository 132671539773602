export class CommonPageModel {
    imageList : any;
}

export class ImageUpLoadDto {
    brandId : string;
    articleList : string;
    imageList : any;
}

export class ImageListModel
{
    id : number;
    Image : string;
}