import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: any, limit = 25, completeWords = false, ellipsis = '...') {
    if (completeWords) {
        var inputWords = value.split(/\s+/);
        if (inputWords.length > limit) {
            value = inputWords.slice(0, value).join(' ') + '…';
        }
    }
    return  value;
  }
}