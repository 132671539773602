<div class="container border-box">
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="form-group col-md-10">
                    <div class="user-pic-control mb-3">
                        <kendo-upload [autoUpload]="false" [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
                            [restrictions]="uploadRestrictions" (select)="selectEventHandler($event)"
                            (clear)="clearEventHandler($event)" (remove)="removeEventHandler($event)"
                            [withCredentials]="false" (complete)="completeEventHandler($event)">
                        </kendo-upload>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="row">
            <div class="col-md-2" *ngFor="let image of ImageList">
                <div *ngIf="image.extension != '.pdf'" class="imagestyle text-center">
                    <img [src]="image.image" alt="image preview" style="width: 200px; padding: 10px;" />
                    <label style="font-size: smaller;overflow-wrap: anywhere;">{{image.name}}</label>
                    <span class="badge-grid-action badge-red" (click)="onDelete(image.id)">
                        <i class="fa fa-trash text-red"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>