import { Component, Injectable, OnInit } from "@angular/core";
import { MasterPage } from "src/app/shared/classes/master-page";
import { CommonPageModel } from "./commonpage.model";
import { FileRestrictions, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';
import { FileModel } from "../../general-configuration/general-configuration/general-configuration.model";
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CommonPageService } from "./commonpage.service";
import { UntypedFormBuilder } from "@angular/forms";
import { UtilityService } from "src/app/core/services/utility.service";

@Component({
    selector: 'app-commonpage',
    templateUrl: './commonpage.component.html',
    styleUrls: ['./commonpage.component.scss']

})
export class CommonPageComponent extends MasterPage<CommonPageModel> implements OnInit {
    public events: string[] = [];
    public imagePreviews: any[] = [];
    public uploadRestrictions: FileRestrictions = {
        allowedExtensions: ['.jpg', '.png', '.pdf', '.jpeg', '.ico']
    };

    public uploadSaveUrl = 'CommonPageImages/saveUrl/'; // should represent an actual API endpoint
    public uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
    fileModel: any;
    ImageList: any;
    constructor(public service: CommonPageService, private formBuilder: UntypedFormBuilder, private utils: UtilityService) {
        super(service);
    }

    ngOnInit() { this.GetAllImage() }
    public GetAllImage() {

        this.service.getAll().subscribe((res) => {
            if (res.length > 0) {
                this.ImageList = res;
            }
        });
    }
    onDelete(id: number) {
        this.service.delete(id).subscribe((res) => {
            this.GetAllImage();
        });
    }
    public clearEventHandler(a: any): void {
        this.imagePreviews = [];
    }
    onSave() { }

    public completeEventHandler(a: any) {

        let ImageUpLoad = new CommonPageModel()
        ImageUpLoad.imageList = this.imagePreviews;
        
        this.service.UploadImage(ImageUpLoad).subscribe((res) => {
            this.GetAllImage();
            this.utils.toast.recordSaved();
        });
    }

    public removeEventHandler(e: RemoveEvent): void {
        const index = this.imagePreviews.findIndex(item => item.uid === e.files[0].uid);

        if (index >= 0) {
            this.imagePreviews.splice(index, 1);
        }
    }

    public selectEventHandler(e: SelectEvent): void {
        const that = this;

        e.files.forEach((file) => {
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const extension = name.substring(lastDot + 1);
            if (!file.validationErrors) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.fileModel = {
                        base64: reader.result,
                        extension: extension,
                    } as FileModel;
                };
                reader.onload = function (ev) {
                    const image = {
                        src: ev.target['result'],
                        uid: file.uid,
                        extension: extension,
                        fileName: name
                    };
                    that.imagePreviews.unshift(image);
                };

                reader.readAsDataURL(file.rawFile);


            }
        });
    }

}



@Injectable()
export class UploadInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url === 'saveUrl') {

            const events: Observable<HttpEvent<any>>[] = [0, 30, 60, 100].map((x) => of(<HttpProgressEvent>{
                type: HttpEventType.UploadProgress,
                loaded: x,
                total: 100
            }).pipe(delay(1000)));

            const success = of(new HttpResponse({ status: 200 })).pipe(delay(1000));
            events.push(success);

            return concat(...events);
        }

        if (req.url === 'removeUrl') {
            return of(new HttpResponse({ status: 200 }));
        }

        return next.handle(req);
    }
}
