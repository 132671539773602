import { Component, OnInit } from '@angular/core';
import { SignalRService } from './core/services/signalr.service';
import { UtilityService } from './core/services/utility.service';
import { Destroyer } from './core/utils/destroyer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent extends Destroyer implements OnInit {
  constructor(private utils: UtilityService, private signalRServer: SignalRService) {
    super();
    this.utils.loadBaseSettings();
  }
  
  title = 'Index Procurement';
  ngOnInit() {
    setTimeout(() => {
      // this.signalRServer.startConnection();
      // this.subs = this.utils.data.get('notify').subscribe((res) => {});
    }, 100);
  }
}
