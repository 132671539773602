import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { BaseApiService } from "src/app/core/services/base-api.service";
import { DataService } from "src/app/core/services/data.service";
import { CommonPageModel } from "./commonpage.model";

@Injectable({
    providedIn: 'root'
})
export class CommonPageService extends BaseApiService<CommonPageModel>{

    constructor(protected dataService: DataService, private httpClient: HttpClient) {
        super('CommonPageImages', dataService);
    }

    UploadImage(data: any): Observable<any> {
        
        return this.dataService.post<any>(`CommonPageImages`, data);
    }

    // GetForLogin(): Observable<any> {
    //     return this.httpClient.post<any>(`CommonPageImages/GetForLogin`, 1, { headers: { hideLoader: 'true' } });
    // }
    VerifyBrandName(name : any): Observable<any> {
        return this.httpClient.get<any>(`IndexBrand/VerifyBrandName/`+ name);
    }
    VerifySupplierName(name : any): Observable<any> {
        return this.httpClient.get<any>(`IndexSupplierBrand/VerifySupplierName/`+ name);
    }
    VerifyCompanyName(name : any): Observable<any> {
        return this.httpClient.get<any>(`company/VerifyCompanyName/`+ name);
    }
    GetForLogin(): Observable<any> {
        return this.httpClient.get<any>(`CommonPageImages/GetForLogin`,  { headers: { hideLoader: 'true' } });
    }
}
