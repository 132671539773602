import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr'
import { PopupModule } from '@progress/kendo-angular-popup';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PipesModule } from './shared/pipes/pipes.module';
import {
  PDFService,
  ExcelService,
  SuspendService,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { MenuModule } from '@progress/kendo-angular-menu';
import { CoreModule } from './core/core.module';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { NgbModalModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderModule } from './core/loader/loader.module';
import {
  DialogService,
  DialogContainerService,
} from '@progress/kendo-angular-dialog';
import { EditInputComponent } from './shared/components/edit-input/edit-input.component';
import { AutofocusDirective } from './shared/components/edit-input/autofocus.directive';
import { LoaderService } from './core/loader/loader.service';
import { SortableModule, SortableService } from '@progress/kendo-angular-sortable';
import { L10N_PREFIX, LocalizationService } from '@progress/kendo-angular-l10n';
import { EditorModule } from '@progress/kendo-angular-editor';
import { UploadModule, UploadService } from '@progress/kendo-angular-upload';
import { UploadInterceptor } from './layout/site-pages/commonpage/commonpage.component';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { AgmCoreModule, AgmMap, MapsAPILoader } from '@agm/core';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ChartsModule } from '@progress/kendo-angular-charts';

import 'hammerjs';
import { TextBoxModule } from '@progress/kendo-angular-inputs';






@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SocialLoginModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      maxOpened: 1,
      timeOut: 5000,
      autoDismiss: true
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDZ-L5EeTw2JhNlxSkWHpwrbgmJuIMukzA'
    }),
    BrowserAnimationsModule,
    LoaderModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    NgbModalModule,
    PopupModule,
    TextBoxModule,
    WindowModule,
    TreeViewModule,
    HammerModule,
    DateInputsModule,
    PipesModule,
    ExcelModule,
    PDFModule,
    NgxPaginationModule,
    SortableModule,
    NgxSmartModalModule.forRoot(),
    MenuModule,
    UploadModule,
    EditorModule,
    ListViewModule,
    IndicatorsModule,
    ChartsModule
  ], schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [DialogService, DialogContainerService, LoaderService, SortableService, LocalizationService, UploadService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true
    },
    { provide: L10N_PREFIX, useValue: '' }
    , {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '73471072450-cu3qd8hkkq00vi0fh3s0eltip5kqm2nt.apps.googleusercontent.com'
            )
          },
        ],
      } as SocialAuthServiceConfig
    }
  ]
  ,
  bootstrap: [AppComponent]
})
export class AppModule { }
