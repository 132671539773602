import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr'
import { Constant } from '../constants/constants';
@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    private toastr: ToastrService
  ) { }


  recordDeleted(): void {
   
    this.success(Constant.message.deleteRecord);
  }
  recordActivated(): void {
     
    this.success(Constant.message.ActivatedRecord);
  }
  recordSaved(): void {
   
    this.success(Constant.message.saveRecord);
  }
  success(message: string): void {
    this.toastr.success(message);
  }
  info(message: string): void {
    this.toastr.info(message);
  }
  warning(message: string): void {
    this.toastr.warning(message);
  }
  error(message: string, title?: string): void {
    this.toastr.error(message);
  }
}
