import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { UtilityService } from '../services/utility.service';
// import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  public currentUser: any;


  constructor(
    private router: Router, private utility: UtilityService,
    private stotageService: StorageService
  ) {

  }
  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.currentUser = this.utility.storage.CurrentUser;
    if (this.currentUser == null) {
      return this.checkAuthentication('');
    }
    var isAuthorized = this.checkUserAuthorization(next.routeConfig.path, this.currentUser.roleId, state.url)
    if (!isAuthorized) {

      // if(this.currentUser.isSuperAdmin){
      //   this.router.navigate([state.url]);
      // }
      // else 
      if (this.currentUser.roleId.indexOf(17) > -1) {
        this.router.navigate(['/index/user/profile']);
        return true;
      }
      else if (this.currentUser.roleId.indexOf(9) > -1) {
        this.router.navigate(['/index/user/invitationDashboard']);
        return true;
      }
      else if (this.currentUser.roleId.indexOf(7) > -1) {
        this.router.navigate(['/index/user/dashboardClient']);
        return true;
      }
     
      else {
        this.router.navigate(['/index/user/profile']);
        return true;

      }
    }

    return this.checkAuthentication('');
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {


    return this.checkAuthentication('');
  }
  checkUserAuthorization(requestedPath: string, userRoleId: number[], url: string) {
    switch (url) {
      // case "/layout/master/profile":
      //   return userRoleId[0] === 17 ;
      //   break;
      case "/index/user/dashboardClient":
        if (this.currentUser.isSuperAdmin || userRoleId.indexOf(7) > -1) { return true } else { return false; };
        break;
      case "/index/user/dashboard":
        if (this.currentUser.isSuperAdmin || userRoleId.indexOf(4) > -1) { return true } else { return false; };
        break;
      case "/index/user/companyProfile":
        return userRoleId.indexOf(4) > -1 || userRoleId.indexOf(16) > -1;
        break;
      case "/index/user/brandProfile":
        return userRoleId.indexOf(9) > -1;
        break;
      case "/index/control/specification":
        if (this.currentUser.isSuperAdmin) { return true } else { return false; };
        break;
      case "/index/user/invitationDashboard":
        return userRoleId.indexOf(9) > -1 || userRoleId.indexOf(17) > -1 || userRoleId.indexOf(16) > -1;
        break;
      case "/index/brand/mybranditems":
        return userRoleId.indexOf(9) > -1;
        break;
    }
    return true;
  }
  private checkAuthentication(url: string): boolean {
      if (this.stotageService.HasToken) {
        return true
      } else {
        this.router.navigate(['/auth'])
        return false
      }
    
  }
}
